<template>
    <div class="promo-code" id="promo-code">
      <div class="temp"></div>
      <div class="content">
        <Loading v-model:active="isLoading"
                   :can-cancel="true"
                   :is-full-page="false"
                   color="#B2CC9B"
                   loader="bars"
                   background-color="#000"
                   :lock-scroll="false"
                   />
        <div class="title">
          <h2 class="color-strong">Delivery  Management</h2>
          <a class="background" data-bs-toggle="modal" data-bs-target="#deliveryModal">Add</a>
        </div>
        <table>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Deliver Charge</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="record" v-for="code in areas" :key="code.id" :data-id="code.id">
              <td data-label="Code">{{ code.id }}</td>
              <td data-label="Code">{{ code?.name }}</td>
              <td data-label="Discount">{{ code?.deliver_charge }}</td>
              <td data-label="Actions">
                <div class="actions">
                    <!-- <v-icon icon="mdi-eye-outline" class="view" /> -->
                    <v-icon @click="change_selected_item(code)" icon="mdi-notebook-edit-outline" class="edit"
                    data-bs-toggle="modal" data-bs-target="#deliveryModal" />
                    <v-icon @click="change_selected_item(code)" icon="mdi-delete-empty-outline" class="delete"
                    data-bs-toggle="modal" data-bs-target="#deletModal" />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="10">
                <v-icon icon="mdi-chevron-left" class="icon previous" />
                <span>{{ currPage }} - {{ totalPages }}</span>
                <v-icon icon="mdi-chevron-right" class="icon next" />
              </td>
            </tr>
          </tfoot>
        </table>
  
        <deliveryModal :current_deliver_charge="current_deliver_charge" :current_name="current_name" :id="current_id" />
        <app-delete-modal :path="'areas'" :id="current_id" modalTitle="Delete Area"/>
      </div>
    </div>
  </template>
  
  <script>
  // Loading
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  
  import store from '@/store'
  
  import AppDeleteModal from '@/components/global/AppDeleteModal.vue'
  import deliveryModal from '@/views/deliveryModal.vue'

  
  export default {
    name: "PromoCodeView",
    data() {
      return {
        areas: [],
        currPage: 1,
        totalPages: 0,
        current_id:'',
        cardId: -1,
        current_deliver_charge:'',
        current_name:'',
        isLoading: false,
      }
    },
    methods: {
        change_selected_item(item){
            this.current_id=item?.id;
            this.current_name=item?.name
            this.current_deliver_charge=item?.deliver_charge
        },
        getExchange() {
        this.isLoading = true;
  
        fetch(this.serverURL + 'api/areas?page=' + this.currPage, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;
            this.areas = data.data;
            
            // Meta
            if(data.meta.total > 0){
              this.currPage = data.meta.currentPage;
              this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
            }else{
              this.currPage = 0;
              this.totalPages = 0;
            }
          })
          .catch(error => {
            console.log(error);
          })
      },
      deleteCode() {
        fetch(this.serverURL + 'api/promo-areas/' + this.cardId,{
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
        })
          .then(response => response.json())
          .then(function(){
            location.reload();
          })
          .catch()
      },
    },
    beforeMount() {
      this.getExchange();
    },
    mounted(){
      let that = this;

      window.$('body').on('click', '.product .content table .actions .view', function() {
        localStorage.setItem('product_id', window.$(this).parents('.record').data('id'));

        let fullPath = '/ProductDetails/' + window.$(this).parents('.record').data('name').replaceAll(' ', '-');
        that.$router.push({ path: fullPath });
        });
  
      // On Delete Clicked
      window.$('body').on('click', '.promo-code .content table .actions .delete,.promo-code .content table .actions .edit', function() {
        that.cardId = window.$(this).parents('.record').data('id');
      });
  
      // On Yes Clicked
      window.$('.promo-code .content .yes-btn').on('click', function() {
        that.deleteCode();
      });
  
      // On Previous Clicked
      window.$('.promo-code .content table .previous').on('click', function() {
        if(that.currPage > 1){
          that.currPage -= 1;
          that.getExchange();
        }
      });
  
      // On Next Clicked
      window.$('.promo-code .content table .next').on('click', function() {      
        if(that.currPage < that.totalPages){
          that.currPage += 1;
          that.getExchange();
        }
      });
    },
    components: {
      AppDeleteModal,
      deliveryModal,
      Loading
    },
  };
  </script>
  
  <style>
  .promo-code{
    display: flex;
  }
  
  /* Temp  */
  .promo-code .temp{
    min-width: 250px;
    transition: all .5s;
  }
  .close-rail .promo-code .temp{
    min-width: 70px;
    transition: all .5s;
  }
  
  /* Content */
  .promo-code .content{
    position: relative;
    width: -webkit-fill-available;
    background-color: #F1F1F1;
    min-height: 89vh;
    padding: 2rem;
    text-align: left;
  }
  @media(max-width: 576px){
    .promo-code .content{
      padding: 1rem .5rem;
    }
  }
  .promo-code .content .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
  .promo-code .content .title h2{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0;
  }
  .promo-code .content .title a{
    text-decoration: none;
    color: #FFF;
    cursor: pointer;
    padding: .7rem 2rem;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 1px 5px #CCC;
  }
  
  /* Table */
  .promo-code .content table img{
    width: 5rem;
  }
  .promo-code .content table .actions{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .promo-code .content table .actions i{
    font-size: 20px;
    padding: 1rem;
    background-color: #e9f3ef;
    border-radius: 10px;
    cursor: pointer;
  }
  .promo-code .content table .actions i:nth-of-type(2){
    margin: 0 .5rem;
  }
  .promo-code .content table .actions i:hover{
    color: #6F825F;
  }
  </style>