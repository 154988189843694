<template>
    <div class="add-category" id="add-category">
      <!-- Modal -->
      <div
        class="modal fade"
        id="exportModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="title">
              <h2 class="color-strong">Export</h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="SendData">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      <label>Start Date</label>
                      <input type="date" v-model="categoryData.start_date" placeholder="Start date"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      <label>End Date</label>
                      <input type="date" v-model="categoryData.end_date" placeholder="End date"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>            
              <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import store from '@/store'
  import axios from 'axios';

  export default {
    name: "AddCategoryModal",
    props:['type'],
    data() {
      return {
        categoryData: {
            end_date: '',
          start_date: '',
        },
        errorMessages: [],
        isLoading: false
      }
    },
    methods: {
      handleImageChange(e) {
        const selectedFile = e.target.files[0];
        this.categoryData.image = selectedFile;
      },
      SendData() {
            if(this.type=='pdf'){
                this.exportPdf()
            }else if(this.type=='excel'){
                this.exportExcel()
            }else if(this.type=='csv'){
                this.exportCsv()
            }
      },
      exportExcel(){
            this.loading_loader=true
            var start = this.categoryData.start_date!=''?`&from_date=${this.categoryData.start_date}`:''
            var end = this.categoryData.end_date!=''?`&to_date=${this.categoryData.end_date}`:''
            axios.get( `${this.serverURL}api/orders-report?file_type=excel${start}${end}`,
            { headers:{
              Authorization: `Bearer ${store.state.accessToken}`
            },
            responseType: 'blob'
            }).then((response) => {
                location.reload();
               this.loading_loader=false
               const link = document.createElement('a');
               link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
    
            link.setAttribute('download', 'report.xlsx');
    
            document.body.appendChild(link);
    
            link.click();
            })
         },
         exportPdf(){
            var start = this.categoryData.start_date!=''?`&from_date=${this.categoryData.start_date}`:''
            var end = this.categoryData.end_date!=''?`&to_date=${this.categoryData.end_date}`:''
            this.loading_loader=true
            axios.get( `${this.serverURL}api/orders-report?file_type=pdf${start}${end}`,
            { headers:{
              Authorization: `Bearer ${store.state.accessToken}`            },
            responseType: 'blob'
            }).then((response) => {
                // location.reload();
               this.loading_loader=false
               const link = document.createElement('a');
               link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
    
            link.setAttribute('download', 'reports.pdf');
    
            document.body.appendChild(link);
    
            link.click();
            })
            this.loading_loader=false
            location.reload();

         },
         exportCsv(){
            var start = this.categoryData.start_date!=''?`&from_date=${this.categoryData.start_date}`:''
            var end = this.categoryData.end_date!=''?`&to_date=${this.categoryData.end_date}`:''
            this.loading_loader=true
            axios.get( `${this.serverURL}api/orders-report?file_type=csv${start}${end}`,
            { headers:{
              Authorization: `Bearer ${store.state.accessToken}`            },
            responseType: 'blob'
            }).then((response) => {
            location.reload();
               this.loading_loader=false
               const link = document.createElement('a');
               link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
    
            link.setAttribute('download', 'report.csv');
    
            document.body.appendChild(link);
    
            link.click();
            })
         },
    },
    watch:{
        id(){
            this.categoryData.name=this.current_name;
            this.categoryData.deliver_charge=this.current_deliver_charge;
        }
    },
    components: {},
  };
  </script>
  
  <style>
  .add-category .modal .modal-content {
    border-radius: 1rem;
    box-shadow: 1px 1px 5px #856161;
    padding: 2rem;
  }
  .add-category .modal .title{
    margin-bottom: 1rem !important;
  }
  .add-category .modal h2{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0;
  }
  
  /* Form */
  .add-category .modal form .ar{
    direction: rtl;
    text-align: right;
  }
  .add-category .modal form label {
    display: block;
    margin: 1rem 0 .5rem 0;
    color: black;
  }
  .add-category .modal form input,
  .add-category .modal form label.upload-input {
    border: 1px solid #CCC;
    background-color: #FFF;
    padding: .5rem 1rem;
    border-radius: 10px;
    width: 90%;
  }
  .add-category .modal form label.upload-input {
    color: #6b6b6b;
    cursor: pointer;
    display: flex;
    margin: 0;
    justify-content: space-between;
  }
  .add-category .modal form label.upload-input img {
    width: 1.2rem;
    height: 1.1rem;
  }
  .add-category .modal form .submit-btn {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 1px 5px #f1e7e7;
    font-weight: bold;
    display: block;
    margin-top: 2rem;
    width: 6rem;
  }
  </style>
  