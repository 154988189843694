<template>
  <div class="header" id="header">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          v-model="drawer"
          :rail="rail"
          permanent
          @click="openNavigationDrawer"
        >
          <div class="head" v-if="!rail">
            <div class="logo">
              <router-link to="/Dashboard">
                <img src="@/assets/logo.svg" alt="Logo Icon" />
              </router-link>
              <v-btn
                variant="text"
                icon="mdi-chevron-left"
                @click.stop="closeNavigationDrawer"
              ></v-btn>
            </div>
          </div>
          <div class="items">
            <router-link class="item dashboard-item" to="/Dashboard" :class="{'dash-pages-active' : activeRouter=='Dashboard'}">
              <v-icon icon="mdi-home-city" />
              <span>Dashboard</span>
            </router-link>
            <router-link class="item category-item" to="/Category">
              <v-icon icon="mdi-ev-plug-type1" />
              <span>Category</span>
            </router-link>
            <router-link class="item subcategory-item" to="/Subcategory">
              <v-icon icon="mdi-ev-plug-type2" />
              <span>Subcategory</span>
            </router-link>
            <a class="item parent-item variant-item">
              <div>
                <v-icon icon="mdi-tune-vertical-variant" />
                <span>Variant</span>
              </div>
              <v-icon class="select-arrow" icon="mdi-chevron-right" />
            </a>
            <router-link class="item color-item child-item" to="/Color">
              <v-icon icon="mdi-brush-variant" />
              <span>Color</span>
            </router-link>
            <router-link class="item size-item child-item" to="/Size">
              <v-icon icon="mdi-resize-bottom-right" />
              <span>Size</span>
            </router-link>
            <router-link class="item product-item" to="/Product">
              <v-icon icon="mdi-shopping" />
              <span>Product</span>
            </router-link>
            <router-link class="item order-item" to="/Order">
              <v-icon icon="mdi-store" />
              <span>Order</span>
            </router-link>
            <a class="item parent-item admins-item">
              <div>
                <v-icon icon="mdi-account-group-outline" />
                <span>Admins</span>
              </div>
              <v-icon class="select-arrow" icon="mdi-chevron-right" />
            </a>
            <router-link class="item role-item child-item" to="/Role">
              <v-icon icon="mdi-gamepad" />
              <span>Role</span>
            </router-link>
            <router-link class="item admin-item child-item" to="/Admin">
              <v-icon icon="mdi-shield-crown-outline" />
              <span>Admin</span>
            </router-link>
            <router-link class="item customer-item" to="/Customer">
              <v-icon icon="mdi-account-tie" />
              <span>Customer</span>
            </router-link>
            <a class="item parent-item content-item">
              <div>
                <v-icon icon="mdi-text-box" />
                <span>Content</span>
              </div>
              <v-icon class="select-arrow" icon="mdi-chevron-right" />
            </a>
            <router-link class="item home-item child-item" to="/Home">
              <v-icon icon="mdi-domain" />
              <span>Constants</span>
            </router-link>
            <router-link class="item aboutus-item child-item" to="/AboutUs">
              <v-icon icon="mdi-domain" />
              <span>About US</span>
            </router-link>
            <router-link class="item privacy-item child-item" to="/PrivacyPolicy">
              <v-icon icon="mdi-police-badge-outline" />
              <span>Privacy Policy</span>
            </router-link>
            <router-link class="item policy-item child-item" to="/ReturnPolicy">
              <v-icon icon="mdi-police-badge" />
              <span>Return Policy</span>
            </router-link>
            <router-link class="item terms-item child-item" to="/Terms">
              <v-icon icon="mdi-text-box-edit" />
              <span>Terms And Conditions</span>
            </router-link>
            <router-link class="item social-item child-item" to="/SocialMedia">
              <v-icon icon="mdi-sticker-emoji" />
              <span>Social Media</span>
            </router-link>
            <router-link class="item banner-item child-item" to="/SliderBanner">
              <v-icon icon="mdi-slide" />
              <span>Slider Banner</span>
            </router-link>
            <router-link class="item exchange-requests-item" to="/exchange-requests">
              <v-icon icon="mdi-sale-outline" />
              <span>Exchange Requests</span>
            </router-link>
            <router-link class="item delivery-management-item" to="/delivery-management">
              <v-icon icon="mdi-sale-outline" />
              <span>Delivery Management</span>
            </router-link>
            <router-link class="item promo-item" to="/PromoCode">
              <v-icon icon="mdi-sale-outline" />
              <span>Promo Code</span>
            </router-link>
            <router-link class="item contact-item" to="/ContactUs">
              <v-icon icon="mdi-message-badge-outline" />
              <span>Messages</span>
            </router-link>
          </div>
        </v-navigation-drawer>
        <v-main></v-main>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      drawer: true,
      rail: false,
    };
  },
  props: ['location'],
  methods: {
    openNavigationDrawer(){
      let windowWidth = window.innerWidth;
      if (windowWidth > 767 && this.rail == true){
        this.rail = false;
        this.$emit('toggle-rail', this.rail);
        window.$('.parent-item').slideDown(1);
        this.checkNavItemActive();
      }
    },
    closeNavigationDrawer(){
      this.rail = true;
      this.$emit('toggle-rail', this.rail);
      window.$('.parent-item').slideUp(1);
      window.$('.child-item').slideDown(1);
    },
    checkNavItemActive(){
      window.$(".header .items .item").removeClass("active");
      window.$('.parent-item .select-arrow').removeClass('rotate');
      window.$('.child-item').slideUp(1);
      switch (this.location) {
        case '/Dashboard':
          window.$(".header .items .item.dashboard-item").addClass("active");
          break;
        case '/Category':
          window.$(".header .items .item.category-item").addClass("active");
          break;
        case '/Subcategory':
          window.$(".header .items .item.subcategory-item").addClass("active");
          break;
        case '/Color':
          window.$(".header .items .item.color-item").addClass("active");
          window.$(".header .items .item.variant-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.color-item,.header .items .item.size-item").slideDown(1);
          break;
        case '/Size':
          window.$(".header .items .item.size-item").addClass("active");
          window.$(".header .items .item.variant-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.color-item,.header .items .item.size-item").slideDown(1);
          break;
        case '/Product':
          window.$(".header .items .item.product-item").addClass("active");
          break;
        case '/Order':
          window.$(".header .items .item.order-item").addClass("active");
          break;
        case '/Role':
          window.$(".header .items .item.role-item").addClass("active");
          window.$(".header .items .item.admins-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.role-item,.header .items .item.admin-item").slideDown(1);
          break;
        case '/Admin':
          window.$(".header .items .item.admin-item").addClass("active");
          window.$(".header .items .item.admins-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.role-item,.header .items .item.admin-item").slideDown(1);
          break;
        case '/Customer':
          window.$(".header .items .item.customer-item").addClass("active");
          break;
        case '/AboutUs':
          window.$(".header .items .item.aboutus-item").addClass("active");
          window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.aboutus-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
          break;
        case '/Home':
        window.$(".header .items .item.home-item").addClass("active");
        window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
        window.$(".header .items .item.home-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
        break;
        case '/PrivacyPolicy':
          window.$(".header .items .item.privacy-item").addClass("active");
          window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.aboutus-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
          break;
        case '/ReturnPolicy':
          window.$(".header .items .item.policy-item").addClass("active");
          window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.aboutus-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
          break;
        case '/Terms':
          window.$(".header .items .item.terms-item").addClass("active");
          window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.aboutus-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
          break;
        case '/SocialMedia':
          window.$(".header .items .item.social-item").addClass("active");
          window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.aboutus-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
          break;
        case '/SliderBanner':
          window.$(".header .items .item.banner-item").addClass("active");
          window.$(".header .items .item.content-item .select-arrow").addClass("rotate");
          window.$(".header .items .item.aboutus-item,.header .items .item.privacy-item,.header .items .item.policy-item,.header .items .item.terms-item,.header .items .item.social-item,.header .items .item.banner-item").slideDown(1);
          break;
        case '/PromoCode':
          window.$(".header .items .item.promo-item").addClass("active");
          break;
          case '/exchange-requests':
          window.$(".header .items .item.exchange-requests-item").addClass("active");
          break;
          case '/ContactUs':
          window.$(".header .items .item.contact-item").addClass("active");
          break;
          case '/delivery-management':
          window.$(".header .items .item.delivery-management-item").addClass("active");
          break;
      }
    },
  },
  mounted(){
    let that = this;

    window.$('.child-item').slideUp(1);

    that.checkNavItemActive();

    // Init Rail Of App Header
    let windowWidth = window.innerWidth;
    if (windowWidth < 767){
      that.closeNavigationDrawer();
    }

    // On Resize Body
    window.$(window).on('resize', function(e){
      windowWidth = e.target.innerWidth;
      if (windowWidth < 767){
        that.closeNavigationDrawer();
      }
    });

    // On Parent-Item Clicked
    window.$('.parent-item').on('click', function() {
      window.$(this).children('.select-arrow').toggleClass('rotate');
    });

    // On Variant-Item Clicked
    window.$('.variant-item').on('click', function() {
      window.$('.color-item,.size-item').slideToggle(1);
    });

    // On Admins-Item Clicked
    window.$('.admins-item').on('click', function() {
      window.$('.role-item,.admin-item').slideToggle(1);
    });

    // On Content-Item Clicked
    window.$('.content-item').on('click', function() {
      window.$('.aboutus-item,.home-item,.privacy-item,.policy-item,.terms-item,.social-item,.banner-item').slideToggle(1);
    });
  },
  watch: {
    location: function() {
      this.checkNavItemActive();
    }
  },
  components: {},
  computed:{
    activeRouter(){
            return this.$route.name;
        },
  }
};
</script>

<style>
.header{
  position: absolute;
  z-index: 999;
}
.header .v-navigation-drawer{
  background-color: #2E3230;
  color: #FFF;
  border: none;
  width: 250px !important;
  transition: all .5s;
}
.close-rail .header .v-navigation-drawer{
  width: 70px !important;
  transition: all .5s;
}
.dash-pages-active {
    color: #B2CC9B !important;
    transition: 0.5s;
}

/* Head */
.header .v-navigation-drawer .head{
  height: 15%;
  padding: 1rem 1.5rem;
}
.header  .v-navigation-drawer .logo{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header  .v-navigation-drawer .logo img{
  width: 5rem;
}
.header  .v-navigation-drawer .logo .v-btn i{
  color: #878A99;
  font-size: 1.8rem;
}

/* Items */
.header .v-navigation-drawer .items{
  overflow: auto;
  height: 85%;
  padding-top: .5rem;
}
.header .v-navigation-drawer .items::-webkit-scrollbar{
  display: none;
}
.close-rail .header .v-navigation-drawer .items{
  height: 100%;
  padding-top: 1rem;
}
.header .v-navigation-drawer .items .item{
  text-decoration: none;
  color: #878A99;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
  transition: all .3s;
  cursor: pointer;
}
.header .v-navigation-drawer .items .parent-item{
  justify-content: space-between;
}
.header .v-navigation-drawer .items .parent-item .rotate{
  transform: rotate(90deg);
}
.header .v-navigation-drawer .items .child-item{
  padding: 0;
  padding-left: 2.5rem;
}
.header .v-navigation-drawer .items .item:hover,
.header .v-navigation-drawer .items .item.active{
  color: #B2CC9B;
}
.close-rail .header .v-navigation-drawer .items .item{
  padding: 0 .5rem;
  justify-content: center;
}
.header .v-navigation-drawer .items .item .v-icon{
  margin-right: .5rem;
}
.close-rail .header .v-navigation-drawer .items .item .v-icon{
  margin-right: 0;
}
.header .v-navigation-drawer .items .item span{
  font-size: 1rem;
}
.close-rail .header .v-navigation-drawer .items .item span {
  display: none;
}

</style>
