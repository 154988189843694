<template>
  <div class="customer" id="customer">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Customer Management</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Orders</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="customer in customers" :key="customer.id" :data-id="customer.id" :data-name="customer.name">
            <td data-label="ID">{{ customer.id }}</td>
            <td data-label="Full Name">{{ customer.name }}</td>
            <td data-label="Email">{{ customer.email }}</td>
            <td data-label="Mobile Number">{{ customer.phone }}</td>
            <td data-label="Orders">{{ customer.number_of_orders }}</td>
            <td data-label="Status">
              <div class="status">
                <label class="switch">
                  <input type="checkbox" :checked="customer.status == 'ACTIVE'"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
            <td data-label="Actions">
              <div class="dropdown">
                <span><v-icon icon="mdi-cog-outline" /></span>
                <div class="dropdown-content">
                  <a href="#" class="password" data-bs-toggle="modal" data-bs-target="#resetPasswordModal">Reset Password</a>
                  <a href="#" class="orders">View Orders</a>
                  <a href="#" class="addresses">View Addresses</a>
                  <a href="#" class="edit" data-bs-toggle="modal" data-bs-target="#editCustomerModal">Edit Details</a>
                  <a href="#" class="delete" data-bs-toggle="modal" data-bs-target="#deletModal">Delete Account</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>

      <reset-password-modal :id="cardId" />
      <edit-customer-modal :id="cardId" />
      <app-delete-modal modalTitle="Delete Customer"/>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'
import ResetPasswordModal from '@/views/Customer/ResetPasswordModal.vue'
import EditCustomerModal from '@/views/Customer/EditCustomerModal.vue'

export default {
  name: "CustomerView",
  data() {
    return {
      customers: [],
      currPage: 1,
      totalPages: 0,
      cardId: -1,
      isLoading: false,
    }
  },
  methods: {
    getCustomers() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/users?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.customers = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteCustomer() {
      fetch(this.serverURL + 'api/users/' + this.cardId,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(function(){
          location.reload();
        })
        .catch()
    },
    activeCustomer(id) {
      fetch(this.serverURL + 'api/users/' + id + '/activate',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    },
    deactiveCustomer(id) {
      fetch(this.serverURL + 'api/users/' + id + '/deactivate',{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then()
        .catch()
    }
  },
  beforeMount() {
    this.getCustomers();
  },
  mounted(){
    let that = this;

    // On Checkbox change
    window.$('body').on('change', '.customer .content table .status .switch input[type="checkbox"]', function() {
      if (this.checked){
        that.activeCustomer(window.$(this).parents('.record').data('id'));
      }else{
        that.deactiveCustomer(window.$(this).parents('.record').data('id'));
      }
    });

    // On View Orders Clicked
    window.$('body').on('click', '.customer .content table .dropdown .orders', function() {

      localStorage.setItem('customer_id', window.$(this).parents('.record').data('id'));

      let fullPath = '/CustomerOrders/' + window.$(this).parents('.record').data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });

    // On View Addresses Clicked
    window.$('body').on('click', '.customer .content table .dropdown .addresses', function() {

      localStorage.setItem('customer_id', window.$(this).parents('.record').data('id'));

      let fullPath = '/CustomerAddresses/' + window.$(this).parents('.record').data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });

    // On Edit,Delete,Password Clicked
    window.$('body').on('click', '.customer .content table .dropdown .edit,.customer .content table .dropdown .delete,.customer .content table .dropdown .password', function() {
      that.cardId =  window.$(this).parents('.record').data('id');
    });

    // On Yes Clicked
    window.$('.customer .content .yes-btn').on('click', function() {
      that.deleteCustomer();
    });

    // On Previous Clicked
    window.$('.customer .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getCustomers();
      }
    });

    // On Next Clicked
    window.$('.customer .content table .next').on('click', function() {      
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getCustomers();
      }
    });
  },
  components: {
    AppDeleteModal,
    ResetPasswordModal,
    EditCustomerModal,
    Loading
  },
};
</script>

<style>
.customer{
  display: flex;
}

/* Temp  */
.customer .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .customer .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.customer .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .customer .content{
    padding: 1rem .5rem;
  }
}
.customer .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.customer .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Table */
.customer .content table .dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.customer .content table .dropdown i{
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}
.customer .content table .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 1rem;
  z-index: 99;
  left: -8rem;
  border-radius: 10px;
  line-height: 1.8;
}
.customer .content table .dropdown-content a{
  text-decoration: none;
  display: block;
  color: #404040;
}
.customer .content table .dropdown-content a:hover{
  color: #6F825F;
}
.customer .content table .dropdown:hover .dropdown-content {
  display: block;
}
</style>