<template>
    <div class="add-category" id="add-category">
      <!-- Modal -->
      <div
        class="modal fade"
        id="deliveryModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="title">
              <h2 class="color-strong">{{ id?'Edit Area':'Add Area' }}</h2>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="SendData">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      <label>Name*</label>
                      <input type="text" v-model="categoryData.name" placeholder="name" required/>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12">
                      <label>Deliver Charge*</label>
                      <input type="text" v-model="categoryData.deliver_charge" placeholder="charge" required/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="error-message">
                <p v-for="error in errorMessages" :key="error">{{ error }}</p>
              </div>            
              <input type="submit" class="submit-btn background" value="Submit" :disabled="isLoading ? true:false" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import store from '@/store'
  
  export default {
    name: "AddCategoryModal",
    props:['id','current_name','current_deliver_charge'],
    data() {
      return {
        categoryData: {
          name: '',
          deliver_charge: '',
        },
        errorMessages: [],
        isLoading: false
      }
    },
    methods: {
      handleImageChange(e) {
        const selectedFile = e.target.files[0];
        this.categoryData.image = selectedFile;
      },
      SendData() {
        if(!this.id){
            this.isLoading = true;
            
            let formData = new FormData();
            formData.append("name", this.categoryData.name);
            formData.append("deliver_charge", this.categoryData.deliver_charge);
            fetch(this.serverURL + 'api/areas',{
                method:  'POST',
                headers: {
                Authorization: `Bearer ${store.state.accessToken}`,
                },
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                this.isLoading = false;

                if (data.status == 200 || data.status == 201){
                    location.reload();
                }else{
                    let messages = [];

                    for(let propt in data.errors){
                    messages.push(data.errors[propt].join(' '));
                    }
                    this.errorMessages = messages;
                }
                })
                .catch(error => {
                console.error(error);
                })
            }else{
                this.isLoading = true;
                
                let formData = new FormData();
                formData.append("name", this.categoryData.name);
                formData.append("deliver_charge", this.categoryData.deliver_charge);
                formData.append("_method", 'PUT');
                fetch(this.serverURL + `api/areas/${this.id}`,{
                    method:  'POST',
                    headers: {
                    Authorization: `Bearer ${store.state.accessToken}`,
                    },
                    body: formData,
                })
                    .then(response => response.json())
                    .then(data => {
                    this.isLoading = false;

                    if (data.status == 200 || data.status == 201){
                        location.reload();
                    }else{
                        let messages = [];

                        for(let propt in data.errors){
                        messages.push(data.errors[propt].join(' '));
                        }
                        this.errorMessages = messages;
                    }
                    })
                    .catch(error => {
                    console.error(error);
                    })
            }   
      }
    },
    watch:{
        id(){
            this.categoryData.name=this.current_name;
            this.categoryData.deliver_charge=this.current_deliver_charge;
        }
    },
    components: {},
  };
  </script>
  
  <style>
  .add-category .modal .modal-content {
    border-radius: 1rem;
    box-shadow: 1px 1px 5px #856161;
    padding: 2rem;
  }
  .add-category .modal .title{
    margin-bottom: 1rem !important;
  }
  .add-category .modal h2{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 0;
  }
  
  /* Form */
  .add-category .modal form .ar{
    direction: rtl;
    text-align: right;
  }
  .add-category .modal form label {
    display: block;
    margin: 1rem 0 .5rem 0;
    color: black;
  }
  .add-category .modal form input,
  .add-category .modal form label.upload-input {
    border: 1px solid #CCC;
    background-color: #FFF;
    padding: .5rem 1rem;
    border-radius: 10px;
    width: 90%;
  }
  .add-category .modal form label.upload-input {
    color: #6b6b6b;
    cursor: pointer;
    display: flex;
    margin: 0;
    justify-content: space-between;
  }
  .add-category .modal form label.upload-input img {
    width: 1.2rem;
    height: 1.1rem;
  }
  .add-category .modal form .submit-btn {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 1px 5px #f1e7e7;
    font-weight: bold;
    display: block;
    margin-top: 2rem;
    width: 6rem;
  }
  </style>
  