<template>
  <app-navbar v-if="!main"/>
  <app-sidebar v-if="!main" @toggle-rail="toggleRail" :location="location"/>
  <router-view />
</template>

<script>
import AppSidebar from '@/components/global/AppSidebar.vue'
import AppNavbar from '@/components/global/AppNavbar.vue'

export default {
  name: 'App',
  data() {
    return {
      location: '/',
      main: true,
    };
  },
  methods: {
    toggleRail(rail){
      if (rail){
        window.$('#app').addClass('close-rail');
      }else{
        window.$('#app').removeClass('close-rail');
      }
    },
    checkMainLocation(){
      if (this.location != '/'){
        this.main = 0;
      } else{
        this.main = 1;
      }
    },
  },
  mounted () {
    // Get Current Location
    this.location = window.location.pathname;

    this.checkMainLocation();

    // On URL Change
    setInterval(() => {
      const currUrl = window.location.pathname;
      if (currUrl != this.location) {
        this.location = currUrl;
        this.checkMainLocation();
      }
    }, 60);
  },
  components: {
    AppSidebar,
    AppNavbar,
  }
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
#app {
  font-family: IBM Plex Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFF;
}
.color{
  color: #B2CC9B !important;
}
.background{
  background-color: #B2CC9B !important;
}
.color-strong{
  color: #6F825F !important;
}
.background-strong{
  background-color: #6F825F !important;
}

/* Table */
table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0 1px 5px #CCC;
}
table tr {
  background-color: #FFF;
  border: 1px solid #ddd;
  padding: .35em;
}
table tfoot tr{
  padding: 0;
}
table th,
table td {
  padding: .625em;
  text-align: center;
}
table th {
  background-color: #F3F3F9;
  color: black;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: .1em;
  text-transform: capitalize;
}
table td{
  color: #404040;
}
table tfoot td .icon {
  padding: 16px;
  background-color: #f1f1f1;
  color: black;
  border-radius: 50%;
  cursor: pointer;
}
table tfoot td .icon:hover {
  background-color: #ddd;
  color: black;
}
table tfoot td span{
  display: inline-block;
  margin: 0 3rem;
}
@media(max-width: 992px) {
  table:not(.no-cards) {
    border: 0;
    box-shadow: none;
  }
  table:not(.no-cards) thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table:not(.no-cards) tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  }
  table:not(.no-cards) tfoot tr{
    margin-bottom: 0;
  }
  table:not(.no-cards) tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
  }
  table:not(.no-cards) tfoot td{
    display: block;
  }
  table:not(.no-cards) tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  table:not(.no-cards) tfoot tr,
  table:not(.no-cards) tbody td:last-child {
    border-bottom: 0;
  }
}


/* Switch */
.switch{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 auto !important;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CCC;
  border: 1px solid #FFF;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #FFF;
  -webkit-transition: .4s;
  transition: .4s;
}
.switch input:checked + .slider {
  background-color: #B2CC9B;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #B2CC9B;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

.vl-overlay{
  position: fixed !important;
}
.no-data{
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  color: #D01B50;
  margin-top: 20vh;
}
.error-message{
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
  color: red;
}
.error-message p{
  margin-bottom: 0;
}
.ql-editor{
  color:black
}
</style>
