<template>
  <div class="navbarr" id="navbarr">
    <div class="temp"></div>
    <div class="content">
      <div class="top-bar">
        <!-- <div class="mode" @click="toggleMode">
          <v-icon icon="mdi-theme-light-dark" />
        </div> -->
        <!-- <div class="notification">
          <v-icon icon="mdi-bell-outline" />
        </div> -->
        <div class="dropdown nav-dropdown notification-dropdown small-status">
            <button class="btn notification  dropdown-toggle dropdown-toggle-nav secondary-color" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" style="position:relative">
                <v-icon icon="mdi-bell-outline" />
                <span v-if="un_read_count!=0" class="button__badge">{{ un_read_count }}</span>
            </button>
            <ul style="max-width:400px;width:400px" @scroll="handleScroll" class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <div class="notification-title d-flex justify-content-between position-sticky top-0" style="background-color:#fff;">
                    <span style="font-size: 21px">Notifications</span>
                    <!-- <button style="background-color:#ebe5e5;border:none;background-color:var(--main-color);" @click="readAllNotifications()">Select all as Read</button> -->
                </div>                
                <li v-for="i in notifications" :key="i" class="not_li" :class="{'readed' : i.is_read==true}">
                    <div  @click="readNotification(i)" class="dropdown-item secondary-color link-menu">
                        <div v-bind:class="{ active: i.read==1 }" class="notification-box recently d-flex">
                            <!-- <UserImage gender="male" image="" width="40" class="user-img me-2"/> -->
                            <div style="text-wrap: balance;">
                                <span>{{ i?.title }}</span>
                                <div class="date">{{i?.body}}</div>
                            </div>
                        </div>
                      </div>
                </li>
                <li style="display:flex;justify-content:center"><div class="lds-dual-ring" v-if="loader_notifications"></div></li>    
            </ul>
        </div>
        <div class="profile">
          <!-- <img src="@/assets/images/navbar/person.jpg" alt="Person Image"> -->
          <div class="name">
            <span class="color-strong">{{ info.name }}</span>
            <!-- <span>{{ info.role }}</span> -->
          </div>
        </div>
          <div class="notification me-0 ms-2" @click="logout">
            <v-icon icon="mdi-logout" style="color:red" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AppNavbar",
  data() {
    return {
      loader_notifications:false,
      lock:true,
    }
  },
  methods: {
    toggleMode(){
      if(this.mode == 'light'){
        store.state.appMode = 'dark';
      }else{
        store.state.appMode = 'light';
      }
    },
    handleScroll: function(el) {
        if((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
            this.showMore();
        }
    },
    readNotification(i){
        fetch(this.serverURL + 'api/notifications/'+i?.id+'/read',{
          method:  'PATCH',
          headers: {
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body:JSON.stringify({
          firebase_token:localStorage.getItem('fcm_token')
        })
        })
          .then(response => response.json())
          .then(() => {
            store.actions.loadNotifications();
          })
          .catch(error => {
            console.error(error);
          })
    },
    showMore(){
        this.loader_notifications=true;
        if(this.lock==true){
            this.lock=false;
            store.actions.loadNotifications(store.state.current_page_notification+1).then((res) => {
            this.loader_notifications = false;
            this.lock=res;
            },error =>{
                this.loader_notifications = false;
                console.log(error);
            });
        }
    },
    logout(){
      
        localStorage.removeItem('admin_token');
        store.state.accessToken = '';

        localStorage.removeItem('admin_info');
        store.state.adminInfo = '';

        this.$router.push({ name: "Login"});
    }
  },
  mounted() {
    //  Sticky Menu
    var $window = window.$(window);
    $window.on("scroll", function () {
      if ($window.scrollTop() > 20) {
        window.$(".navbarr").addClass("sticky");
      } else {
        window.$(".navbarr").removeClass("sticky");
      }
    });
    store.actions.loadNotifications()
  },
  components: {},
  computed: {
    info () {
      return store.state.adminInfo;
    },
    lang () {
      return store.state.appLang;
    },
    mode () {
      return store.state.appMode;
    },
    unRead(){
      return store.state.unRead
    },
    notifications(){
      return store.state.notifications
    },
    un_read_count(){
      var i = 0
      this.notifications.forEach(e => {
        if(e.is_read==false){
          i=i+1
        }
      });
      return i
    }
  },
};
</script>

<style>
.navbarr{
  display: flex;
}
.navbarr.sticky{
  position: sticky;
  width: 100%;
  top: 0;
  margin-top: 0;
  z-index: 99;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #B2CC9B; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #B2CC9B; 
}

/* Temp  */
.navbarr .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .navbarr .temp{
  min-width: 70px;
  transition: all .5s;
}
.not_li{
  background-color: #eeeeee;
}
.readed{
  background-color: white
}

/* Content */
.navbarr .content{
  width: -webkit-fill-available;
}
.navbarr .content .top-bar{
  background-color: #FFF;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.5rem;
  height: 11vh;
  box-shadow: 0 1px 5px #CCC !important;
}
@media(max-width: 576px){
  .navbarr .content .top-bar{
    padding: 0 .5rem;
  }
}
.navbarr .content .top-bar .mode,
.navbarr .content .top-bar .notification{
  margin-right: 1rem;
  padding: .5rem;
  transition: all .5s;
  cursor: pointer;
  border-radius: 50%;
}
.navbarr .content .top-bar .mode:hover,
.navbarr .content .top-bar .notification:hover{
  background-color: #F3F3F9;
}
.navbarr .content .top-bar i{
  color: #878A99;
}
.navbarr .content .top-bar .profile{
  border-radius: 5px;
  background-color: #F3F3F9;
  color: #7FB4F9;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 1rem;
}
.navbarr .content .top-bar .profile img{
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: .5rem;
}
@media(max-width: 767px){
  .navbarr .content .top-bar .profile img{
    margin-right: 0;
  }
  .navbarr .content .top-bar .profile .name{
    display: none;
  }
}
.navbarr .content .top-bar .profile .name span:last-of-type{
  color: #878A99;
  display: block;
  font-size: .7rem;
}
.notification-box {
        display: flex;
        padding: 10px 16px;
        font-size: 12px;
    
    }
    .notification-title {
        font-size: 14px;
        font-weight: 600;
        padding: 9px 16px;

    }
    .button__badge {
            background-color: #fa3e3e;
            border-radius: 4px;
            color: white;
            padding: 0px 3px;
            font-size: 8px;
            position: absolute;
            top: 5px;
            right: 7px;
        }
    .notification-info {
        padding-right: 25px;
        
    }
    .notification-box span {
        color: var(--secondary-color);
        font-weight: 600;
    }
    .notification-box{}
    .notification-box  .date {
        margin-top: 8px;
        color: #45454582;

    }
      
    .notification .dropdown-toggle::after {
        display: none !important;
    }
    .notification {
    
    background-color: transparent;
    border: none;
    padding: 0px;
    }
    .notification-icon:hover path{            
        stroke: var(--main-color);
    }
    .notification-dropdown .dropdown-menu {
        left: -228px;
        height: 356px;
        top: 36px;
        overflow-y: auto;
        padding: 0px;
    }
    .notification-dropdown .dropdown-toggle::after{
        display: none !important;
    }
</style>