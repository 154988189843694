<template>
  <div class="cancel-order" id="cancel-order">
    <!-- Modal -->
    <div
      class="modal fade"
      id="cancelOrderModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="title">
              <h2 class="color-strong">Cancel Order</h2>
            </div>
          <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
        </div>
          <div class="modal-body"><span style="color:black">Are You Sure ?</span></div>
          <div @click.prevent="" class="modal-footer">
            <button type="button" class="btn no-btn" data-bs-dismiss="modal">
              No
            </button>
            <button @click.prevent="deleteItem()"  type="button" class="btn outline-btn" style="background-color: #dc3545">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props:["path","id"],
  name: "CancelOrderModal",
  data() {
    return {
    }
  },
    methods: {
      deleteItem() {
        fetch(this.serverURL + `api/catalog/orders/` + this.id+`/cancel`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
        })
          .then((response) => {response.json();location.reload();
            }
        ).catch(()=>{
          console.log('><<<<<<<<<<<<<<<<<<');
          })
          .then(function(){
          
          })
          .catch(()=>{
          })
      },
  },
  components: {},
};
</script>

<style>
.cancel-order .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
  padding: 2rem;
}
.cancel-order .modal .title{
  margin-bottom: 1rem !important;
}
.cancel-order .modal h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
</style>
