<template>
  <div class="dashboard" id="dashboard">
    <div class="temp"></div>
    <div class="content">
      <div class="cards">
        <div class="row">
          <div class="col-lg-6 col-xl-4">
            <div class="card">
              <div class="detail">
                <span>{{ data.registered_users }}</span>
                <span>Registered Users</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-account-group"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card special">
              <div class="detail">
                <span>{{ data.successfull_orders }}</span>
                <span>Successful Orders</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-shopping"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card">
              <div class="detail">
                <span>{{ data.placed_orders }}</span>
                <span>Placed Orders</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-map-marker"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card special">
              <div class="detail">
                <span>{{ data.on_the_way_orders }}</span>
                <span>On The Way Orders</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-highway"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card">
              <div class="detail">
                <span>{{ data.delivered_orders }}</span>
                <span>Delivered Orders</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-package-variant-closed-check"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card special">
              <div class="detail">
                <span>{{ data.canceled_orders }}</span>
                <span>Canceled Orders</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-cancel"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card">
              <div class="detail">
                <span>{{ data.total_products }}</span>
                <span>Products</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-shopping"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card special">
              <div class="detail">
                <span>{{ data.total_categories }}</span>
                <span>Categories</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-ev-plug-type1"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card">
              <div class="detail">
                <span>{{ data.total_sub_categories }}</span>
                <span>Subcategories</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-ev-plug-type2"/>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4">
            <div class="card special">
              <div class="detail">
                <span>{{ data.total_overall_sales }} KWD</span>
                <span>Overall Sales</span>
              </div>
              <div class="icon">
                <v-icon icon="mdi-currency-usd"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "DashboardView",
  data() {
    return {
      data: {
        registered_users: 0,
        successfull_orders: 0,
        placed_orders: 0,
        on_the_way_orders: 0,
        delivered_orders: 0,
        canceled_orders: 0,
        total_products: 0,
        total_categories: 0,
        total_sub_categories: 0,
        total_overall_sales: 0,
      },
    }
  },
  methods: {
    getDashboardInfo() {
      fetch(this.serverURL + 'api/dashboard', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`},
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          this.data = data.data;
        }
      })
      .catch(error => {
        console.log(error);
      })
    },
  },
  beforeMount() {
    this.getDashboardInfo();
  },
  components: {},
};
</script>

<style>
.dashboard {
  display: flex;
}

/* Temp  */
.dashboard .temp {
  min-width: 250px;
  transition: all .5s;
}

.close-rail .dashboard .temp {
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.dashboard .content {
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}

.dashboard .content .cards .card {
  background-color: #303030;
  border: none;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 1.5rem;
  height: 88%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-shadow: 0 1px 5px #CCC;
}

@media (max-width: 576px) {
  .dashboard .content {
    padding: 1rem .5rem;
  }

  .dashboard .content .cards .card {
    padding: 1rem;
  }
}

.dashboard .content .cards .card.special {
  background-color: #FFF;
}

.dashboard .content .cards .card .detail span:first-of-type {
  color: #FFF;
  font-size: 30px;
}

.dashboard .content .cards .card.special .detail span:first-of-type {
  color: #404040;
}

.dashboard .content .cards .card .detail span:last-of-type {
  display: block;
  color: #D6D6D6;
  font-size: 20px;
}

.dashboard .content .cards .card.special .detail span:last-of-type {
  color: #808080;
}

.dashboard .content .cards .card .icon {
  background-color: #424242;
  border-radius: 50%;
  padding: .8rem;
}

.dashboard .content .cards .card.special .icon {
  background-color: #dce5e1;
}

.dashboard .content .cards .card .icon i {
  color: #B2CC9B;
  font-size: 30px;
}

.dashboard .content .cards .card.special .icon i {
  color: #6F825F;
}
</style>