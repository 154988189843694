<template>
  <div class="order" id="order">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Order Management</h2>
        <div v-if="!loading_loader" class="dropdown d-inline">
            <button class="background" style="padding:1px 14px" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
               Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
               <li @click="export_type='excel'" data-bs-toggle="modal" data-bs-target="#exportModal" class="dropdown-item btns" style="cursor: pointer;">Excel</li>
               <li  @click="export_type='pdf'" data-bs-toggle="modal" data-bs-target="#exportModal" class="dropdown-item btns" style="cursor: pointer;" >PDF</li>
               <li  @click="export_type='csv'" data-bs-toggle="modal" data-bs-target="#exportModal" class="dropdown-item btns" style="cursor: pointer;">CSV</li>
            </ul>
         </div>
      </div>
      <div class="filter">
        <span>{{ orders.length }} Orders</span>
        <Datepicker
          class="date-picker"
          v-model="date"
          placeholder='Enter Date'
          format="yyyy-MM-dd"
          range
        ></Datepicker>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Deliver Charge</th>
            <th scope="col">Status</th>
            <th scope="col">Refund Reference</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="order in orders" :key="order.id" :data-id="order.id" :data-name="order.user?order.user.name:''">
            <td data-label="ID">{{ order.id }}</td>
            <td data-label="Date/Time">{{ order.created_at.substring(0, 10) }}<br/>{{ order.created_at.substring(11, 19) }}</td>
            <td data-label="Full Name">{{ order.user.name }}</td>
            <td data-label="Email">{{ order.user.email }}</td>
            <td data-label="Mobile Number">{{ order.user.phone }}</td>
            <td data-label="Deliver Charge">{{ order.deliver_charge }} KD</td>
            <td data-label="Total Amount">{{ order.total_amount }} KD</td>
            <td data-label="Status">
              <v-select
                class="status"
                v-model="order.status"
                :items="status"
                item-title="name"
                item-value="value"
                variant="outlined"
                @update:modelValue="(event) => { changeStatus(event, order.id) }"
              ></v-select>
            </td>
            <td data-label="Refund Reference">{{ order.refund_reference?order.refund_reference:'-' }}</td>
            <td data-label="Actions">
              <div class="actions">
                <v-icon icon="mdi-eye-outline" class="view" />
                <v-icon @click="current_id=order.id" icon="mdi-close-octagon" class="cancel"
                  data-bs-toggle="modal" data-bs-target="#cancelOrderModal" />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="10">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>

      <exportModal :type="export_type"></exportModal>
      <cancel-order-modal :id="current_id"  :path="'catalog/orders'" />
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

// Date Picker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import CancelOrderModal from '@/views/Order/CancelOrderModal.vue'
import exportModal from '@/views/exportModal.vue'

export default {
  name: "OrderView",
  data() {
    return {
      orders: [],
      date: null,
      loading_loader:false,
      processedDate: '',
      current_id:'',
      export_type:'',
      status: [
        {
          name: 'Placed',
          value: 'PLACED'
        },
        {
          name: 'On The Way',
          value: 'ON_THE_WAY'
        },
        {
          name: 'Delivered',
          value: 'DELIVERED'
        }
      ],
      currPage: 1,
      totalPages: 1,
      cardId: -1,
      isLoading: false,
    }
  },
  methods: {
    getOrders() {
      this.isLoading = true;

      let url = '';
      if (this.date)
        url = this.serverURL + 'api/catalog/orders?page=' + this.currPage + '&filter[created_at]=' + this.processedDate;
      else
        url = this.serverURL + 'api/catalog/orders?page=' + this.currPage;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.orders = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    
    changeStatus(status, id) {      
      let formData = new FormData();
      formData.append("status", status);

      fetch(this.serverURL + 'api/catalog/orders/' + id + '/change-status',{
        method: "POST",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        })
        .catch()
    }
  },
  beforeMount() {
    this.getOrders();
  },
  mounted(){
    let that = this;

    // On View Clicked
    window.$('body').on('click', '.order .content table .actions .view', function() {
      localStorage.setItem('order_id', window.$(this).parents('.record').data('id'));

      let fullPath = '/OrderDetails/' + window.$(this).parents('.record').data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });

    // On Cancel Clicked
    window.$('body').on('click', '.order .content table .actions .cancel', function() {      
      that.cardId =  window.$(this).parents('.record').data('id');
      console.log(that.cardId);
    });

    // On Previous Clicked
    window.$('.order .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getOrders();
      }
    });

    // On Next Clicked
    window.$('.order .content table .next').on('click', function() {      
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getOrders();
      }
    });
  },
  watch: {
    date: function(){
      if(this.date){
        let string = '';
        string += this.date[0].toISOString().substring(0, 10);
        if(this.date[1]){
          string += ','
          string += this.date[1].toISOString().substring(0, 10);
        }
        this.processedDate = string;
      }
      this.getOrders();
    },
  },
  components: {
    CancelOrderModal,
    Datepicker,
    Loading,exportModal
  },
};
</script>

<style>
.order{
  display: flex;
}

/* Temp  */
.order .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .order .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.order .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .order .content{
    padding: 1rem .5rem;
  }
}
.order .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.order .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Filter */
.order .content .filter{
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.order .content .filter > span{
  color: black;
  font-weight: bold;
  font-size: 20px;
}
.order .content .filter .date-picker{
  width: initial;
}
@media(max-width: 576px){
  .order .content .filter .date-picker{
    width: 10rem;
  }
}
.order .content .filter .date-picker .dp--header-wrap,
.order .content .filter .date-picker .dp--tp-wrap{
  color: black;
}

/* Table */
.order .content table img{
  width: 5rem;
}
.order .content table .actions{
  display: flex;
  align-items: center;
  justify-content: center;
}
.order .content table .actions i{
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}
.order .content table .actions i:nth-of-type(2){
  margin: 0 .5rem;
}
.order .content table .actions i:hover{
  color: #6F825F;
}

/* V-Select */
.order .content table .v-select{
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.order .content table .v-select .v-field__input{
  padding: .5rem 1rem;
  min-height: 2.8rem;
}
.order .content table .v-select .v-field{
  border-radius: 10px;
}
.order .content table .v-select span,
.order .content table .v-select .v-icon{
  color: black;
}
.order .content table .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.order .content table .v-select label{
  margin: 0 1rem;
}
.order .content table .v-select .v-input__details{
  display: none;
}

/* Init Table */
@media(max-width: 1200px) {
  .order table:not(.no-cards) {
    border: 0;
    box-shadow: none;
  }
  .order table:not(.no-cards) thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .order table:not(.no-cards) tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  } 
  .order table:not(.no-cards) tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
    text-align: right;
  }
  .order table:not(.no-cards) tfoot td{
    display: block;
  }
  .order table:not(.no-cards) tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .order table:not(.no-cards) tbody td:last-child {
    border-bottom: 0;
  }
}
</style>