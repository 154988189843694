<template>
  <div class="order-details" id="order-details">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading" :can-cancel="true" :is-full-page="false" color="#B2CC9B" loader="bars"
        background-color="#000" :lock-scroll="false" />
      <div class="title">
        <h2 class="color-strong">Order Details</h2>
        <div class="d-flex align-items-center gap-2">
          <v-icon v-if="Object.keys(info).length > 0" icon="mdi-printer" class="background-strong print" />
          <router-link class="color" to="/Order">Back</router-link>
        </div>
      </div>
      <div class="details">
        <div class="item">
          <div class="row">
            <div class="col-6">Order ID</div>
            <div class="col-6">{{ info.if }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Order Date</div>
            <div class="col-6">{{ info.created_at?info.created_at.substring(0, 10):'' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Order Time</div>
            <div class="col-6">{{ info.created_at?info.created_at.substring(11, 19):'' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Full Name</div>
            <div class="col-6">{{ info.user?info.user.name:'' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Email</div>
            <div class="col-6">{{ info.user?info.user.email:'' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Mobile Number</div>
            <div class="col-6">{{ info.user?info.user.phone:'' }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Delivery Address</div>
            <div class="col-6">{{ info.address?info.address.title:'-' }}</div>
          </div>
        </div>
        <div class="item" v-if="info.products && info.products.length > 0">
          <div class="row">
            <div class="col-12">Products Details:</div>
            <div class="col-12">
              <table class="no-cards">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Size</th>
                    <th scope="col">Color</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="record" v-for="product in info.products" :key="product.id">
                    <td data-label="Name">{{ product.name }}</td>
                    <td data-label="Color">{{ product.product_color_size.color?product.product_color_size.color.name:'-' }}</td>
                    <td data-label="Size">{{ product.product_color_size.size?product.product_color_size.size.name:'-' }}</td>
                    <td data-label="Quantity">{{ product.quantity }}</td>
                    <td data-label="Price">{{ product.price }} KD</td>
                  </tr>
                  <!-- <tr class="record result">
                    <td data-label="Name"></td>
                    <td data-label="Color"></td>
                    <td data-label="Size"></td>
                    <td data-label="Quantity"></td>
                    <td data-label="Price">= 90.000 KD</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Deliver Charge</div>
            <div class="col-6">{{ info.deliver_charge }} KD</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Total Amount</div>
            <div class="col-6">{{ info.total_amount }} KD</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Status</div>
            <div class="col-6">{{ info.status }}</div>
          </div>
        </div>
        <div class="item">
          <div class="row">
            <div class="col-6">Refund Reference</div>
            <div class="col-6">{{ info.refund_reference?info.refund_reference:'-' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

// PDF Maker
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "OrderDetailsView",
  props: {
    name: String
  },
  data() {
    return {
      info: {},
      contacts: {
        phone_number: '',
        email: ''
      },
      isLoading: false,
    }
  },
  methods: {
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/orders/' + localStorage.getItem('order_id'), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.info = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getContacts() {
      fetch(this.serverURL + 'api/contacts', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {          
          for(let i=0; i < data.data.length; i++){
            if(data.data[i].type == 'contact')
              this.contacts.phone_number = data.data[i].value;
            else if(data.data[i].type == 'email')
              this.contacts.email = data.data[i].value;   
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };

        img.src = url;
      });
    },
    async print() {
      let product = {}, color = '', size = '';
      let body = [
          [{ text: 'Name', style: 'tableHeader' }, { text: 'Color', style: 'tableHeader' }, { text: 'Size', style: 'tableHeader' }, { text: 'Quantity', style: 'tableHeader' }, { text: 'Price', style: 'tableHeader' }],
        ];
      
      for(let i = 0; i < this.info.products.length; i++){
        product = this.info.products[i];

        if(product.product_color_size.color)
          color = product.product_color_size.color.name;
        else
          color = '-';

        if(product.product_color_size.size)
          size = product.product_color_size.size.name;
        else
          size = '-';
        
        body.push([product.name, color, size, product.quantity, product.price+' KD']);
      }

      let docDefinition = {
        content: [
          {
            image: await this.getBase64ImageFromURL(require('@/assets/logo.png')),
            width: 80,
            higth: 80,
            alignment: "center",
            margin: [0, 0, 0, 20],
          },
          {
            columns: [
              {
                text: 'Order ID',
                style: 'title'
              },
              {
                text: this.info.id,
                style: 'value'
              },
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Order Date',
                style: 'title'
              },
              {
                text: this.info.created_at.substring(0, 10),
                style: 'value'
              },
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Order Time',
                style: 'title'
              },
              {
                text: this.info.created_at.substring(11, 19),
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Full Name',
                style: 'title'
              },
              {
                text: this.info.user.name,
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Email',
                style: 'title'
              },
              {
                text: this.info.user.email,
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Mobile Number',
                style: 'title'
              },
              {
                text: this.info.user.phone,
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Delivery Address',
                style: 'title'
              },
              {
                text: 'Damas',
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            text: 'Products Details:',
            style: ['item', 'title'],
          },
          {
            alignment: 'center',
            table: {
              headerRows: 1,
              widths: ['*', '*', '*', '*', '*'],
              body: body
            },
            layout: 'lightHorizontalLines',
            style: 'table',
          },
          {
            columns: [
              {
                text: 'Deliver Charge',
                style: 'title'
              },
              {
                text: this.info.deliver_charge + ' KD',
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            columns: [
              {
                text: 'Total Amount',
                style: 'title'
              },
              {
                text: this.info.total_amount + ' KD',
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            alignment: 'center',
            text: '__________________________________________________________',
            style: 'line',
          },
          {
            alignment: 'center',
            columns: [
              {
                text: 'Tiny Koala Email Address',
                style: 'title'
              },
              {
                text: this.contacts.email,
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            alignment: 'center',
            columns: [
              {
                text: 'Tiny Koala Phone Number',
                style: 'title'
              },
              {
                text: this.contacts.phone_number,
                style: 'value'
              }
            ],
            style: 'item',
          },
          {
            alignment: 'center',
            text: '© 2024 Tiny Koala. By Line',
            style: 'copywrite'
          },
          {
            alignment: 'center',
            text: '__________________________________________________________',
            style: 'lastLine',
          },
        ],
        styles: {
          id: {
            fontSize: 15,
            color: '#6F825F',
            bold: true,
          },
          item: {
            margin: [0, 15, 0, 0],
          },
          title: {
            fontSize: 12,
            bold: true,
            color: "#000000",
          },
          value: {
            fontSize: 12,
            color: "#404040",
          },
          table: {
            margin: [0, 10, 0, 0],
          },
          tableHeader: {
            color: '#6F825F',
            fontSize: 12,
            bold: true,
          },
          line: {
            margin: [0, 50, 0, 0],
            color: '#6F825F'
          },
          lastLine: {
            margin: [0, 15, 0, 0],
            color: '#6F825F'
          },
          copywrite: {
            color: '#6F825F',
            margin: [0, 20, 0, 0],
          },
        }
      };
      pdfMake.createPdf(docDefinition).open();
    },
  },
  beforeMount() {
    this.getInfo();
    this.getContacts();
  },
  mounted() {
    let that = this;
    
    // On Print Clicked
    window.$('body').on('click', '.order-details .content .title .print', function () {
      that.print();
    });
  },
  components: {
    Loading
  },
};
</script>

<style>
.order-details {
  display: flex;
}

/* Temp  */
.order-details .temp {
  min-width: 250px;
  transition: all .5s;
}

.close-rail .order-details .temp {
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.order-details .content {
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}

@media(max-width: 576px) {
  .order-details .content {
    padding: 1rem .5rem;
  }
}

.order-details .content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.order-details .content .title h2 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

.order-details .content .title i {
  font-size: 25px;
  padding: 1.2rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}

/* Details */
.order-details .content .details .item {
  margin-top: 1rem;
}

.order-details .content .details .item .row div:first-of-type {
  color: black;
  font-weight: bold;
}

.order-details .content .details .item .row div:last-of-type {
  color: #404040;
}

.order-details .content .details .item table {
  margin-top: 1rem;
}

.order-details .content .details .item table .result {
  font-weight: bold;
}

/* Init Table */
@media(max-width: 576px) {
  .order-details table {
    border: 0;
    box-shadow: none;
  }

  .order-details table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .order-details table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  }

  .order-details table tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
    text-align: right;
  }

  .order-details table tfoot td {
    display: block;
  }

  .order-details table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .order-details table tbody td:last-child {
    border-bottom: 0;
  }

  .order-details table tbody .result td:not(:last-of-type) {
    display: none;
  }
}
</style>