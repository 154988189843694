<template>
  <div class="slider-banner" id="slider-banner">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Slider Banner Management</h2>
        <a class="background" data-bs-toggle="modal" data-bs-target="#addBannerModal">Add</a>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Image</th>
            <th scope="col">Title</th>
            <th scope="col">Linked</th>
            <th scope="col">To</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="record" v-for="banner in banners" :key="banner.id" :data-id="banner.id">
            <td data-label="ID">{{ banner.id }}</td>
            <td data-label="Image"><img :src="banner.image" alt="Banner Image"></td>
            <td data-label="Title">{{ banner.title }}</td>
            <td data-label="Linked">{{ banner.sliderable_type?banner.sliderable_type.split('_').join(' '):'UnLinked' }}</td>
            <td data-label="To">{{ banner.sliderable_type?banner.sliderable_object.name:'-' }}</td>
            <td data-label="Actions">
              <div class="actions">
                <v-icon icon="mdi-notebook-edit-outline" class="edit"
                  data-bs-toggle="modal" data-bs-target="#editBannerModal" />
                <v-icon icon="mdi-delete-empty-outline" class="delete"
                  data-bs-toggle="modal" data-bs-target="#deletModal" />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <v-icon icon="mdi-chevron-left" class="icon previous" />
              <span>{{ currPage }} - {{ totalPages }}</span>
              <v-icon icon="mdi-chevron-right" class="icon next" />
            </td>
          </tr>
        </tfoot>
      </table>

      <add-banner-modal />
      <edit-banner-modal :id="updateId" @closeModal="closeModal"/>
      <app-delete-modal modalTitle="Delete Banner" />
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import AppDeleteModal from '@/components/global/AppDeleteModal.vue'
import AddBannerModal from '@/views/Content/SliderBanner/AddBannerModal.vue'
import EditBannerModal from '@/views/Content/SliderBanner/EditBannerModal.vue'

export default {
  name: "CategoryView",
  data() {
    return {
      banners: [],
      currPage: 1,
      totalPages: 0,
      deleteId: -1,
      updateId: -1,
      isLoading: false,
    }
  },
  methods: {
    closeModal(){
      this.updateId = -1;
    },
    getBanners() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/sliders?page=' + this.currPage, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          this.banners = data.data;
          
          // Meta
          if(data.meta.total > 0){
            this.currPage = data.meta.currentPage;
            this.totalPages = Math.ceil(data.meta.total / data.meta.perPage);
          }else{
            this.currPage = 0;
            this.totalPages = 0;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteBanner() {
      fetch(this.serverURL + 'api/sliders/' + this.deleteId,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(function(){
          location.reload();
        })
        .catch()
    }
  },
  beforeMount() {
    this.getBanners();
  },
  mounted(){
    let that = this;

    // On Delete Clicked
    window.$('body').on('click', '.slider-banner .content table .actions .delete', function() {
      that.deleteId = window.$(this).parents('.record').data('id');
    });

    // On Update Clicked
    window.$('body').on('click', '.slider-banner .content table .actions .edit', function() {
      that.updateId = window.$(this).parents('.record').data('id');
    });

    // On Yes Clicked
    window.$('.slider-banner .content .yes-btn').on('click', function() {
      that.deleteBanner();
    });

    // On Previous Clicked
    window.$('.slider-banner .content table .previous').on('click', function() {
      if(that.currPage > 1){
        that.currPage -= 1;
        that.getBanners();
      }
    });

    // On Next Clicked
    window.$('.slider-banner .content table .next').on('click', function() {      
      if(that.currPage < that.totalPages){
        that.currPage += 1;
        that.getBanners();
      }
    });
  },
  components: {
    AppDeleteModal,
    AddBannerModal,
    EditBannerModal,
    Loading
  },
};
</script>

<style>
.slider-banner{
  display: flex;
}

/* Temp  */
.slider-banner .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .slider-banner .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.slider-banner .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .slider-banner .content{
    padding: 1rem .5rem;
  }
}
.slider-banner .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.slider-banner .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
.slider-banner .content .title a{
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
  padding: .7rem 2rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 1px 5px #CCC;
}

/* Table */
.slider-banner .content table img{
  width: 5rem;
}
.slider-banner .content table .actions{
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-banner .content table .actions i{
  font-size: 20px;
  padding: 1rem;
  background-color: #e9f3ef;
  border-radius: 10px;
  cursor: pointer;
}
.slider-banner .content table .actions i:nth-of-type(2){
  margin: 0 .5rem;
}
.slider-banner .content table .actions i:hover{
  color: #6F825F;
}
</style>