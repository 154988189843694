import { reactive } from 'vue'
import axios from 'axios';

const store = ({
  state: reactive({
    accessToken: null,
    adminInfo: null,
    appLang: null,
    appMode: null,
    last_page_notification:null,
    current_page_notification:1,
    notifications:[],
    unRead:null
  }),
  getters: {
    getAccessToken() {
      return store.state.accessToken
    },
    getAdminInfo() {
      return store.state.adminInfo
    },
    getAppLang() {
      return store.state.appLang
    },
    getAppMode() {
      return store.state.appMode
    },
  },
  mutations: {
  },
  actions: {
    initializeAccessToken() {
      store.state.accessToken = localStorage.getItem('admin_token')?localStorage.getItem('admin_token'):''
    },
    initializeAdminInfo() {
      store.state.adminInfo = localStorage.getItem('admin_info')?JSON.parse(localStorage.getItem('admin_info')):''
    },
    initializeAppLang() {
      store.state.appLang = localStorage.getItem('app_lang')?localStorage.getItem('app_lang'):'en'
    },
    initializeAppMode() {
      store.state.appMode = localStorage.getItem('app_mode')?localStorage.getItem('app_mode'):'light'
    },
    loadNotifications(value){
      var page = ''
      if(value && value<=store.state.last_page_notification){
           page = `page=${value}`
      }else if (value && value>store.state.last_page_notification){
         return Promise.resolve(true);
      }else{
         store.state.notifications=[];
          page =`page=1`
      }
      return axios.get(`https://tiny-koala-backend.squaretech.tech/api/notifications?${page}`,{headers: {Authorization: `Bearer ${store.state.accessToken}`,'Content-Type': 'multipart/form-data'}
      }).then((response) => {
          response.data.data.forEach(e => {
              store.state.notifications.push(e);
          });
          store.state.last_page_notification=response.data.meta.lastPage;
          store.state.current_page_notification=response.data.meta.currentPage;
          store.state.unRead=response.data.meta.unread_notifications_count;
          return Promise.resolve(true)
      },error=>{
          console.log(error);
          return Promise.reject(error)

      })
  },
  }
})

export default store