import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/Auth/LoginView.vue";
import Dashboard from '@/views/Dashboard/DashboardView.vue'
import Category from '@/views/Category/CategoryView.vue'
import CategoryProducts from '@/views/Category/CategoryProductsView.vue'
import Subcategory from '@/views/Subcategory/SubcategoryView.vue'
import SubcategoryProducts from '@/views/Subcategory/SubcategoryProductsView.vue'
import Color from '@/views/Color/ColorView.vue'
import Size from '@/views/Size/SizeView.vue'
import Product from '@/views/Product/ProductView.vue'
import ProductDetails from '@/views/Product/ProductDetailsView.vue'
import Order from '@/views/Order/OrderView.vue'
import OrderDetails from '@/views/Order/OrderDetailsView.vue'
import Role from '@/views/Role/RoleView.vue'
import Admin from '@/views/Admin/AdminView.vue'
import Customer from '@/views/Customer/CustomerView.vue'
import CustomerOrders from '@/views/Customer/CustomerOrdersView.vue'
import CustomerAddresses from '@/views/Customer/CustomerAddressesView.vue'
import AboutUs from '@/views/Content/AboutUsView.vue'
import Home from '@/views/Content/HomeView.vue'
import PrivacyPolicy from '@/views/Content/PrivacyPolicyView.vue'
import ReturnPolicy from '@/views/Content/ReturnPolicyView.vue'
import Terms from '@/views/Content/TermsView.vue'
import SocialMedia from '@/views/Content/SocialMediaView.vue'
import SliderBanner from '@/views/Content/SliderBanner/SliderBannerView.vue'
import PromoCode from '@/views/PromoCode/PromoCodeView.vue'
import ContactUs from '@/views/ContactUs/ContactUsView.vue'
import Exchange from '@/views/exchangeRequests.vue'
import Delivery from '@/views/DeliveryManage.vue'
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import store from '@/store'
const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Category',
    name: 'Category',
    component: Category
  },
  {
    path: '/Subcategory',
    name: 'Subcategory',
    component: Subcategory
  },
  {
    path: '/CategoryProducts/:name',
    name: 'CategoryProducts',
    component: CategoryProducts,
    props: true
  },
  {
    path: '/SubcategoryProducts/:name',
    name: 'SubcategoryProducts',
    component: SubcategoryProducts,
    props: true
  },
  {
    path: '/Color',
    name: 'Color',
    component: Color,
  },
  {
    path: '/delivery-management',
    name: 'delivery',
    component: Delivery,
  },
  {
    path: '/Size',
    name: 'Size',
    component: Size,
  },
  {
    path: '/exchange-requests',
    name: 'exchange',
    component: Exchange,
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/ProductDetails/:name',
    name: 'ProductDetails',
    component: ProductDetails,
    props: true
  },
  {
    path: '/Order',
    name: 'Order',
    component: Order,
  },
  {
    path: '/OrderDetails/:name',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true
  },
  {
    path: '/Role',
    name: 'Role',
    component: Role,
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/Customer',
    name: 'Customer',
    component: Customer,
  },
  {
    path: '/CustomerOrders/:name',
    name: 'CustomerOrders',
    component: CustomerOrders,
    props: true
  },
  {
    path: '/CustomerAddresses/:name',
    name: 'CustomerAddresses',
    component: CustomerAddresses,
    props: true
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/ReturnPolicy',
    name: 'ReturnPolicy',
    component: ReturnPolicy,
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/SocialMedia',
    name: 'SocialMedia',
    component: SocialMedia,
  },
  {
    path: '/SliderBanner',
    name: 'SliderBanner',
    component: SliderBanner,
  },
  {
    path: '/PromoCode',
    name: 'PromoCode',
    component: PromoCode,
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// function random_string(length) {
//   let result = '';
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   const charactersLength = characters.length;
//   let counter = 0;
//   while (counter < length) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     counter += 1;
//   }
//   return result;
// }
// export function register_fcm_token(loggedIn, fcm_language, fcm_token){
// 	var local_device_token = localStorage.getItem('device_token');
// 	var local_fcm_token = localStorage.getItem('fcm_token');
// 	var local_fcm_language = localStorage.getItem('fcm_language');
  
// 	if(!local_device_token) {
// 		local_device_token = random_string(25);
// 		local_fcm_token = "";
// 		local_fcm_language = "";
// 		localStorage.setItem('device_token', local_device_token);
// 		localStorage.setItem('fcm_token', '');
// 		localStorage.setItem('fcm_language', '');
// 	}
  
// 	if(loggedIn && (local_fcm_token != fcm_token || local_fcm_language != fcm_language)){
// 		localStorage.setItem('fcm_token', fcm_token);
// 		localStorage.setItem('fcm_language', fcm_language);
		
// 		axios.post(`${api_url}/fcm_token`,{
// 			device_token: local_device_token,
// 			fcm_token: fcm_token,
// 			language: fcm_language,
// 		},{
// 			headers: {...authHeader()},
// 		}).then((res) => {
			
// 		},error =>{
			
// 		});
// 	}
//   }

var firebaseConfig = {
  "apiKey": "AIzaSyBAvW9TVx68npNUihtAnlxOAVsLJROA7tE",
  "authDomain": "tiny-koala-555a5.firebaseapp.com",
  "projectId": "tiny-koala-555a5",
  "storageBucket": "tiny-koala-555a5.appspot.com",
  "messagingSenderId": "510550426483",
  "appId": "1:510550426483:web:5ebf0a3ce1a1805ebd7b06",
  "measurementId": "G-W8N0BESSPF"

}

router.beforeEach(() => {
		if(firebaseConfig){

			const messaging = getMessaging(initializeApp(JSON.parse('{"apiKey": "AIzaSyBAvW9TVx68npNUihtAnlxOAVsLJROA7tE","authDomain": "tiny-koala-555a5.firebaseapp.com","projectId": "tiny-koala-555a5","storageBucket": "tiny-koala-555a5.appspot.com","messagingSenderId": "510550426483","appId": "1:510550426483:web:5ebf0a3ce1a1805ebd7b06","measurementId": "G-W8N0BESSPF"}')));
      console.log('after getMessaging');
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          
          // Get the token
          getToken(messaging, { vapidKey: 'BOwd4zRrEuX3HUZ9jAU97teV0GCqLhgv4TiH773SG8EKGDTbG-wZepquiQX-F9EIUehAbjteaqWss3AAMeaZ9yw' })
            .then((currentToken) => {
              if (currentToken) {
                console.log('currentToken', currentToken);
                localStorage.setItem('fcm_token', currentToken);
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
      console.log(store);
      if(localStorage.getItem('admin_token')!='' && localStorage.getItem('admin_token')){
        store.actions.loadNotifications();
      }
			onMessage(messaging, (payload) => {
				console.log('fb Message received. ', payload);
				// const nuxtApp = useNuxtApp()
				// Toast.fire({
				// 	title: payload?.data?.body,
				// 	iconHtml:'<img class="notification_image" src="'+storage_url+'/'+payload?.data?.icon+'"/>',
				// 	customClass:{
				// 		iconHtml:'notification_image',
				// 		icon:'notification_icon'
				// 	},
				// });
				// store.actions.loadNotifications();
				
				// TODO: handle notification
			});
		}	

});
export default router
