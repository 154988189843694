<template>
  <div class="about-us" id="about-us">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Home Content</h2>
      </div>
      <form @submit.prevent="UpdateData">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>Our Categories*</label>
                <QuillEditor class="en_categories_content" theme="snow" v-model:content="homeData.en_categories_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>فئاتنا*</label>
                <QuillEditor class="ar_categories_content" theme="snow" v-model:content="homeData.ar_categories_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div clasbs="col-12">
                <label>Best Sellings*</label>
                <QuillEditor class="en_best_sellings_content" theme="snow" v-model:content="homeData.en_best_sellings_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>الأكثر مبيعا*</label>
                <QuillEditor class="ar_best_sellings_content" theme="snow" v-model:content="homeData.ar_best_sellings_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>New Arrivals*</label>
                <QuillEditor class="en_new_arrivals_content" theme="snow" v-model:content="homeData.en_new_arrivals_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>الأحدث*</label>
                <QuillEditor class="ar_new_arrivals_content" theme="snow" v-model:content="homeData.ar_new_arrivals_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>Super Sale*</label>
                <QuillEditor class="en_super_sale_content" theme="snow" v-model:content="homeData.en_super_sale_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>التخفيضات*</label>
                <QuillEditor class="ar_super_sale_content" theme="snow" v-model:content="homeData.ar_super_sale_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>You May Like*</label>
                <QuillEditor class="en_may_like_content" theme="snow" v-model:content="homeData.en_may_like_content" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>قد يعجبك*</label>
                <QuillEditor class="ar_may_like_content" theme="snow" v-model:content="homeData.ar_may_like_content" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label>Search Empty Result*</label>
                <QuillEditor class="en_search" theme="snow" v-model:content="homeData.en_search" toolbar="full" contentType="html" placeholder="Enter Content" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row ar">
              <div class="col-12">
                <label>نتيجة البحث الفارغة*</label>
                <QuillEditor class="ar_search" theme="snow" v-model:content="homeData.ar_search" toolbar="full" contentType="html" placeholder="ادخل المحتوى" />
              </div>
            </div>
          </div>
        </div>
        <div class="error-message">
          <p v-for="error in errorMessages" :key="error">{{ error }}</p>
        </div>
        <input type="submit" class="submit-btn background" value="Save" :disabled="isLoading ? true:false" />
      </form>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

// Quill Editor
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

export default {
  name: "HomeView",
  data() {
    return {
      homeData: {
        id: -1,
        en_categories_content: '',
        ar_categories_content: '',
        en_best_sellings_content: '',
        ar_best_sellings_content: '',
        en_new_arrivals_content: '',
        ar_new_arrivals_content: '',
        en_may_like_content: '',
        ar_search:'',
        en_search:'',
        ar_may_like_content: '',
        en_super_sale_content: '',
        ar_super_sale_content: '',
        image: null,
      },
      image_url: '',
      errorMessages: [],
      isLoading: false,
    }
  },
  methods: { 
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/cms/pages/home', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          
          this.homeData.id = data.data.id;
          
          this.homeData.en_categories_content = data.data.translations.filter(item => { return item.name == "categories" && item.locale == 'en' })[0].content
          this.homeData.ar_categories_content = data.data.translations.filter(item => { return item.name == "categories" && item.locale == 'ar' })[0].content
          this.homeData.en_best_sellings_content = data.data.translations.filter(item => { return item.name == "best_sellings" && item.locale == 'en' })[0].content
          this.homeData.ar_best_sellings_content = data.data.translations.filter(item => { return item.name == "best_sellings" && item.locale == 'ar' })[0].content
          this.homeData.en_new_arrivals_content = data.data.translations.filter(item => { return item.name == "new_arrivals" && item.locale == 'en' })[0].content
          this.homeData.ar_new_arrivals_content = data.data.translations.filter(item => { return item.name == "new_arrivals" && item.locale == 'ar' })[0].content
          this.homeData.en_may_like_content = data.data.translations.filter(item => { return item.name == "you_may_like" && item.locale == 'en' })[0].content
          this.homeData.ar_may_like_content = data.data.translations.filter(item => { return item.name == "you_may_like" && item.locale == 'ar' })[0].content
          this.homeData.ar_search = data.data.translations.filter(item => { return item.name == "search" && item.locale == 'ar' })[0].content
          this.homeData.en_search = data.data.translations.filter(item => { return item.name == "search" && item.locale == 'en' })[0].content

          this.homeData.en_super_sale_content = data.data.translations.filter(item => { return item.name == "super_sale" && item.locale == 'en' })[0].content
          this.homeData.ar_super_sale_content = data.data.translations.filter(item => { return item.name == "super_sale" && item.locale == 'ar' })[0].content
          
          window.$('.about-us .en_categories_content .ql-editor').html(this.homeData.en_categories_content);
          window.$('.about-us .ar_categories_content .ql-editor').html(this.homeData.ar_categories_content);
          window.$('.about-us .en_best_sellings_content .ql-editor').html(this.homeData.en_best_sellings_content);
          window.$('.about-us .ar_best_sellings_content .ql-editor').html(this.homeData.ar_best_sellings_content);
          window.$('.about-us .en_new_arrivals_content .ql-editor').html(this.homeData.en_new_arrivals_content);
          window.$('.about-us .ar_new_arrivals_content .ql-editor').html(this.homeData.ar_new_arrivals_content);
          window.$('.about-us .en_may_like_content .ql-editor').html(this.homeData.en_may_like_content);
          window.$('.about-us .ar_may_like_content .ql-editor').html(this.homeData.ar_may_like_content);
          window.$('.about-us .ar_search .ql-editor').html(this.homeData.ar_search);
          window.$('.about-us .en_search .ql-editor').html(this.homeData.en_search);
          window.$('.about-us .en_super_sale_content .ql-editor').html(this.homeData.en_super_sale_content);
          window.$('.about-us .ar_super_sale_content .ql-editor').html(this.homeData.ar_super_sale_content);
          
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData() {
      this.isLoading = true;

      let formData = new FormData();

      formData.append("en[categories]", this.homeData.en_categories_content); 
      formData.append("ar[categories]", this.homeData.ar_categories_content);
      formData.append("en[best_sellings]", this.homeData.en_best_sellings_content);
      formData.append("ar[best_sellings]", this.homeData.ar_best_sellings_content);
      formData.append("en[new_arrivals]", this.homeData.en_new_arrivals_content);
      formData.append("ar[new_arrivals]", this.homeData.ar_new_arrivals_content);
      formData.append("en[you_may_like]", this.homeData.en_may_like_content);
      formData.append("ar[you_may_like]", this.homeData.ar_may_like_content);
      formData.append("en[super_sale]", this.homeData.en_super_sale_content);
      formData.append("ar[super_sale]", this.homeData.ar_super_sale_content);
      formData.append("en[search]", this.homeData.en_search);
      formData.append("ar[search]", this.homeData.ar_search);

      fetch(this.serverURL + 'api/updateHome/' + this.homeData.id,{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getInfo();
  },
  mounted(){
  },
  components: {
    Loading,
    QuillEditor
  },
};
</script>

<style>
.about-us{
  display: flex;
}

/* Temp  */
.about-us .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .about-us .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.about-us .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .about-us .content{
    padding: 1rem .5rem;
  }
}
.about-us .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.about-us .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Quill Editor */
.about-us .content .ql-toolbar{
  background-color: #F3F3F9;
  border-radius: 10px 10px 0 0;
}
.about-us .content .ql-container{
  height: 10rem;
  background-color: #FFF;
  border-radius: 0 0 10px 10px;
}

/* Form */
.about-us .content form .preview{
  display: flex;
  align-items: flex-end;
}
.about-us .content form .ar label{
  direction: rtl;
  text-align: right;
}
.about-us .content form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.about-us .content form input,
.about-us .content form label.upload-input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.about-us .content form label.upload-input {
  color: #6b6b6b;
  cursor: pointer;
  display: flex;
  margin: 0;
  justify-content: space-between;
}
.about-us .content form label.upload-input img {
  width: 1.2rem;
  height: 1.1rem;
}
.about-us .content form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}
</style>