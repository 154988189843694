<template>
  <div class="delete-modal" id="delete-modal">
    <!-- Modal -->
    <div
      class="modal fade"
      id="deletModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are You Sure ?</div>
          <div @click.prevent="" class="modal-footer">
            <span style="color:red;font-size: 11px;">{{ error_message }}</span>
            <button type="button" class="btn no-btn" data-bs-dismiss="modal">
              No
            </button>
            <button @click.prevent="deleteItem()"  type="button" class="btn outline-btn" style="background-color: #dc3545">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "DeleteModalView",
  props: ["modalTitle","id","path"],
  data(){
    return{
      error_message:''
    }
  },
  methods: {
    deleteItem() {
      fetch(this.serverURL + `api/${this.path}/` + this.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            // If the response is not OK, throw an error to be caught by catch
            // throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data.status, "<><><324874237493794923");
          if(data.status==200){
            location.reload()
          }else{
            this.error_message=data.message
          }
        })
        .catch((error) => {
          // Log any error that occurs during the fetch or in the then blocks
          console.error("An error occurred:", error.message);
        });
    },
  },
  mounted() {
  },
  components: {},
  watch:{
    id(){
      this.error_message=''
    }
  }
};
</script>

<style>
.delete-modal .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
}
.delete-modal .modal .modal-title {
  color: #dc3545;
  font-weight: bold;
}
.delete-modal .modal .modal-body{
  color: black;
}
.delete-modal .modal .no-btn {
  background-color: #6c757d;
  color: #fff;
}
.delete-modal .modal .yes-btn {
  background-color: #dc3545;
  color: #fff;
}
.delete-modal .modal .no-btn:hover,
.delete-modal .modal .yes-btn:hover {
  box-shadow: 1px 1px 5px #ccc;
}
</style>
