<template>
  <div class="social-media" id="social-media">
    <div class="temp"></div>
    <div class="content">
      <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="false"
                 color="#B2CC9B"
                 loader="bars"
                 background-color="#000"
                 :lock-scroll="false"
                 />
      <div class="title">
        <h2 class="color-strong">Social Media Content</h2>
      </div>
      <form @submit.prevent="UpdateData">
        <div class="row">
          <div class="col-md-6">
            <label>Facebook Link</label>
            <input type="text" v-model="socialData.facebook" placeholder="Facebook Link" />
          </div>
          <div class="col-md-6">
            <label>Instagram Link</label>
            <input type="text" v-model="socialData.instagram" placeholder="Instagram Link" />
          </div>
          <div class="col-md-6">
            <label>X Link</label>
            <input type="text" v-model="socialData.x" placeholder="X Link" />
          </div>
          <div class="col-md-6">
            <label>Whatsapp Number</label>
            <input type="text" v-model="socialData.whatsapp" placeholder="WhatsApp Number" />
          </div>
          <div class="col-md-6">
            <label>Contact Number</label>
            <input type="text" v-model="socialData.contact_number" placeholder="Contact Number" />
          </div>
          <div class="col-md-6">
            <label>Email Address</label>
            <input type="email" v-model="socialData.email" placeholder="Email Address" />
          </div>
        </div>
        <div class="error-message">
          <p v-for="error in errorMessages" :key="error">{{ error }}</p>
        </div>
        <input type="submit" class="submit-btn background" value="Save" :disabled="isLoading ? true:false" />
      </form>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

export default {
  name: "SocialMediaView",
  data() {
    return {
      socialData: {
        facebook: '',
        instagram: '',
        x: '',
        whatsapp: '',
        contact_number: '',
        email: ''
      },
      errorMessages: [],
      isLoading: false,
    }
  },
  methods: {
    getContacts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/contacts', {
        method: "GET",
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          
          for(let i=0; i < data.data.length; i++){
            if(data.data[i].type == 'facebook')
              this.socialData.facebook = data.data[i].value;
            else if(data.data[i].type == 'instagram')
              this.socialData.instagram = data.data[i].value;
            else if(data.data[i].type == 'whatsapp')
              this.socialData.whatsapp = data.data[i].value;
            else if(data.data[i].type == 'x')
              this.socialData.x = data.data[i].value;
            else if(data.data[i].type == 'contact')
              this.socialData.contact_number = data.data[i].value;
            else if(data.data[i].type == 'email')
              this.socialData.email = data.data[i].value;   
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    UpdateData() {
      this.isLoading = true;

      let formData = new FormData();
      if (this.socialData.facebook)
        formData.append("facebook", this.socialData.facebook);
      if (this.socialData.instagram)
        formData.append("instagram", this.socialData.instagram);
      if (this.socialData.whatsapp)
        formData.append("whatsapp", this.socialData.whatsapp);
      if (this.socialData.contact_number)
        formData.append("contact", this.socialData.contact_number);
      if (this.socialData.email)
        formData.append("email", this.socialData.email);
      if (this.socialData.x)
        formData.append("x", this.socialData.x);
      formData.append("_method", 'PUT');

      fetch(this.serverURL + 'api/update-contacts',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 200){
            location.reload();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
  },
  mounted(){
    this.getContacts();
  },
  components: {
    Loading,
  },
};
</script>

<style>
.social-media{
  display: flex;
}

/* Temp  */
.social-media .temp{
  min-width: 250px;
  transition: all .5s;
}
.close-rail .social-media .temp{
  min-width: 70px;
  transition: all .5s;
}

/* Content */
.social-media .content{
  position: relative;
  width: -webkit-fill-available;
  background-color: #F1F1F1;
  min-height: 89vh;
  padding: 2rem;
  text-align: left;
}
@media(max-width: 576px){
  .social-media .content{
    padding: 1rem .5rem;
  }
}
.social-media .content .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.social-media .content .title h2{
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

/* Form */
.social-media .content form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: black;
}
.social-media .content form input {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: .5rem 1rem;
  border-radius: 10px;
  width: 90%;
}
.social-media .content form .submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  display: block;
  margin-top: 2rem;
  width: 6rem;
}
</style>